@use './assets/sass/variables' as *;
@use './assets/sass/mixins.scss' as *;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;500;600;700;900&display=swap');

html,
body,
body * {
	margin: 0;
	font-family: 'Open Sans', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	// font-size: calc(10px + 2vmin);
	// font-size: 18px;
}
body {
	overflow: hidden;
	touch-action: manipulation;
}
code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
img {
	max-width: 100%;
}
a:not(.antd) {
	@include link;
}
.App {
	background: radial-gradient(#64ebff, #657e82) rgb(102, 215, 240);
	display: flex;
	min-height: 100vh;
	background: $appBlue;
}
.container {
	width: 100%;
	max-width: 400px;
	margin: auto;
	padding: 0px 15px;
}

.containerStyled {
	background-color: #022442;
	min-height: 80vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: $white;
	box-shadow: 0px 1px 23px -4px #474a4c;
}

.t-inherit {
	font: inherit;
	color: inherit;
}

.f-12 {
	font-size: 12px;
}

.f-07 {
	font-size: 0.7rem;
}

.mute {
	color: $mute;
}

.t-center {
	text-align: center;
}

.t-left {
	text-align: left;
}

.t-right {
	text-align: right;
}

.d-block {
	display: block;
}

.bg-dark {
	background: $black;
}

.ul-reset {
	margin: 0;
	padding: 0;
	list-style: none;
}

.border-1 {
	border: 1px solid rgba(0, 0, 0, 0.125);
}

.pd-0 {
	padding: 0;
}
.pd-20 {
	padding: 2vw;
}

.mb-0 {
	margin-bottom: 0;
}
.mb-3 {
	margin-bottom: 1rem !important;
}

.mr-3,
.mx-3 {
	margin-right: 1rem !important;
}
.mt-0,
.my-0 {
	margin-top: 0 !important;
}

.mt-3,
.my-3 {
	margin-top: 1rem !important;
}

.clearfix::after {
	display: block;
	clear: both;
	content: '';
}

.float-left {
	float: left !important;
}

.float-right {
	float: right !important;
}

.pointer {
	cursor: pointer;
}

.ant-layout,
.wd-100 {
	width: 100%;
}

.ant-col {
	padding: 0 0.5rem;
}

.ant-row.row {
	display: flex;
	justify-content: space-between;
}

/* bootstrap Helpers */
/* card styles */

.h-100 {
	height: 100% !important;
}
.bd-placeholder-img {
	font-size: 1.125rem;
	text-anchor: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* media stylels */

.align-self-center {
	-ms-flex-item-align: center !important;
	align-self: center !important;
}

.align-self-start {
	-ms-flex-item-align: start !important;
	align-self: flex-start !important;
}

.align-self-end {
	-ms-flex-item-align: end !important;
	align-self: flex-end !important;
}

.rounded-circle {
	border-radius: 50% !important;
}

.d-flex {
	display: -ms-flexbox !important;
	display: flex !important;
}

.flex-row {
	-ms-flex-direction: row !important;
	flex-direction: row !important;
}
.flex-row-reverse {
	-ms-flex-direction: row-reverse !important;
	flex-direction: row-reverse !important;
}

.justify-content-center {
	-ms-flex-pack: center !important;
	justify-content: center !important;
}

.justify-content-end {
	-ms-flex-pack: end !important;
	justify-content: flex-end !important;
}

.justify-content-start {
	-ms-flex-pack: start !important;
	justify-content: flex-start !important;
}

.justify-content-between {
	-ms-flex-pack: justify !important;
	justify-content: space-between !important;
}

.justify-content-around {
	-ms-flex-pack: distribute !important;
	justify-content: space-around !important;
}

.overflow-hidden {
	overflow: hidden;
}
.wd-50 {
	width: 50%;
}
.container {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}

.containerSmall {
	max-width: 55%;
}

.dynamicRenderer {
	min-height: 10px;
	margin: 0.5vw 0;
	p {
		margin: auto;
	}
	pre {
		font: inherit;
	}
	b {
		font-weight: normal;
	}
	#plugin {
		width: 100%;
	}
	#plugin .socialCard {
		max-width: 100%;
	}
}
.dynamicRenderer > * {
	flex: 1;
}

@media (min-width: 576px) {
	.container {
		max-width: 540px;
	}
}

@media (min-width: 768px) {
	.container {
		max-width: 720px;
	}
	aside.ant-layout-sider {
		height: 100%;
	}
	aside + section.ant-layout {
		max-width: calc(100% - 200px) !important;
		margin-left: auto;
		transition: all 0.2s;
		margin-top: 58px;
	}

	.ant-layout-sider-collapsed + section.ant-layout {
		max-width: calc(100% - 80px) !important;
		margin-left: auto;
	}

	.ant-layout-sider-collapsed + section.ant-layout .appHeader {
		left: 80px;
	}
}

@media (min-width: 992px) {
	.container {
		max-width: 960px;
	}
}

@media (min-width: 1200px) {
	.container {
		max-width: 1140px;
	}
}
/* bootstrap Helpers */

@media screen and (max-width: 768px) {
	.pdx-0-m {
		padding-right: 0;
		padding-left: 0;
	}
	.pdx-10-m {
		padding-right: 10px;
		padding-left: 10px;
	}
	.containerSmall {
		max-width: 100%;
	}

	.dynamicRenderer #plugin {
		margin: 0 !important;
	}
	.dynamicRenderer #plugin .socialCard {
		width: 100%;
		white-space: initial;
	}
}
