.podcast-container {
	display: flex;
	gap: 25px;
	@media screen and (max-width: 1000px) {
		flex-direction: column;
		gap: 10px;
	}
	.cover-art {
		height: 200px;
		border-radius: 10px;
		cursor: pointer;
		object-fit: cover;
	}
	.podcast-info {
		display: flex;
		flex-direction: column;
		gap: 15px;

		.podcast-date-type-duration {
			display: flex;
			align-items: center;
			gap: 8px;
			padding-bottom: 5px;
			.date {
				text-transform: uppercase;
			}
			.type {
				text-transform: uppercase;
			}
			.circle-divider {
				display: flex;
				width: 4px;
				height: 4px;
				background: #ffffff;
				border-radius: 50%;
			}
		}
		.title {
			font-size: 16px;
			font-weight: 600;
			padding-bottom: 5px;
		}
		.description {
			&.pinned {
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 4;
				line-clamp: 4;
				-webkit-box-orient: vertical;
				width: 100%;
			}
		}
		.podcast-actions {
			display: flex;
			align-items: center;
			gap: 5px;
			.play-pause-btn {
				cursor: pointer;
				display: flex;
				align-items: center;
				gap: 7px;
				padding: 10px 20px;
				width: fit-content;
				border-radius: 50px;
				svg {
					font-size: 12px;
				}
			}
		}
	}
	&.show-side-player {
		flex-direction: column;
	}
}
