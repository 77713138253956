.up-next-container {
	position: absolute;
	z-index: 10;
	height: 100%;
	width: 100%;
	top: 0;
	right: 0;
	border-radius: 12px;
	padding: 25px;
	overflow-x: hidden;
	overflow-y: scroll;

	@media screen and (max-width: 640px) {
		position: absolute;
		right: 0;
		bottom: 0;
		width: 100vw;
		height: 100vh;
	}

	.currently-playing-container {
		display: flex;
		flex-direction: column;
		gap: 15px;
		margin-bottom: 20px;
		justify-content: space-between;
		.currently-playing {
			display: flex;
			gap: 15px;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			.podcast-info {
				display: flex;
				gap: 10px;
				align-items: center;
				.cover-art {
					width: 60px;
					min-height: 60px;
					border-radius: 6px;
					object-fit: cover;
				}
				.podcast-title {
					font-weight: 500;
					padding-bottom: 5px;
					line-height: normal;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					line-clamp: 2;
					-webkit-box-orient: vertical;
					width: 100%;
				}
			}
			.play-pause-btn {
				cursor: pointer;
				display: flex;
				align-items: center;
				gap: 5px;
				padding-right: 5px;
				width: fit-content;
				border-radius: 50px;
				svg {
					width: 14px;
					height: 14px;
				}
			}
		}
	}
	.section-header {
		display: flex;
		align-items: center;
		font-size: 16px;
		font-weight: 600;
		gap: 8px;
		.back-btn {
			width: 30px;
			min-height: 30px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			cursor: pointer;
			svg {
				cursor: pointer;
				font-size: 18px;
			}
		}
	}
	.up-next-podcasts {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}
}
