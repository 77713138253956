.player-controls-container {
	.autoplay-container,
	.autoplay-toggle,
	.play-btns {
		display: flex;
		align-items: center;
		gap: 8px;
	}
	.autoplay-container {
		justify-content: space-between;
		padding-bottom: 8px;
		font-weight: 500;
		svg {
			cursor: pointer;
			font-size: 16px;
		}
	}
	.play-btns {
		justify-content: center;
		font-size: 24px;
		padding-top: 5px;
		.icon {
			cursor: pointer;
		}
		.play-pause-btn {
			cursor: pointer;
			width: 100px;
			height: 100px;
			font-size: 38px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			margin: 0 8px;
		}
	}
	.duration {
		display: flex;
		justify-content: space-between;
	}
}
