.player-preview-container {
	.cover-art-container {
		width: 100%;
		height: 175px;
		video,
		.cover-art {
			width: 100%;
			height: 100%;
			border-radius: 10px;
			object-fit: cover;
		}
		margin-bottom: 15px;
	}
	.title-actions {
		display: flex;
		justify-content: space-between;
		gap: 10px;

		.player-preview-title {
			font-size: 16px;
			font-weight: 600;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 1;
			line-clamp: 1;
			-webkit-box-orient: vertical;
		}
		.actions {
			cursor: pointer;
			line-height: normal;
			font-size: 18px;
			display: flex;
			align-items: center;
		}
	}
	.date {
		text-transform: uppercase;
		padding-bottom: 25px;
	}
}
