.floating-preview {
	display: flex;
	align-items: center;
	gap: 10px;
	padding-left: 8px;
	.cover-art {
		object-fit: cover;
		border-radius: 4px;
		cursor: pointer;
		height: 40px;
		width: 40px;
	}
	.podcast-title {
		font-weight: 600;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		line-clamp: 2;
		-webkit-box-orient: vertical;
		line-height: normal;
	}
}
