.floating-controls-pill {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 5px;

	.podcast-btn {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: 40px;
		border-radius: 25px;
		cursor: pointer;
	}

	.play-pause-btn {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 16px;
		width: 45px;
		height: 45px;
		border-radius: 25px;
		cursor: pointer;
	}
	.menu-btn {
		cursor: pointer;
		display: flex;
	}
}

.floating-action-menu {
	display: flex;
	flex-direction: column;
	gap: 10px;
	.floating-action-opt {
		display: flex;
		gap: 8px;
		align-items: center;
		cursor: pointer;
	}
}
