@use '../../../assets/sass/mixins' as *;

.now-playing-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	position: relative;

	.bottom {
		padding-top: 10px;
		width: 100%;
	}
}
