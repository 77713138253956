.podcast-list-container {
	width: 100%;
	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-bottom: 15px;
		gap: 15px;
		.section-title {
			font-size: 22px;
			font-weight: 500;
		}
		.sort-container {
			cursor: pointer;
			display: flex;
			align-items: center;
			gap: 6px;
		}
	}
	.podcast-list {
		display: flex;
		gap: 20px;
		flex-wrap: wrap;
	}
}
