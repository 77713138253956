$white: white;
$black: black;
$mute: #b4b4b4;
$darkBlue: #001120;
$appBlue: #03284c;
$headerBlue: #00284e;
$default-header: #043258;
$default-menu: #022442;
$default-menu-text: #a8b2bd;

$light: #adadad;
$success: #5cb85c;
$primary: #000000;
$info: #62b1f6;
$warning: #f0ad4e;
$dark: #565353;
$Rounded: #000000;
$danger: #d95350;
