// @use '../../assets/sass/variables' as *;
@use '../../assets/sass/mixins' as *;

$dark-text: #444444;
$lighter-text: #888888;

.notificationsContainer {
	color: rgba(0, 0, 0, 0.8);
	max-height: 80vh;
	overflow: scroll;
	.notificationsSearch {
		@include pageSearchBar;
	}
	.notifications {
		color: $dark-text;
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin: 5px 0;
		.section-header {
			font-size: 16px;
			font-weight: 600;
			margin: 12px 0 0 14px;
		}
		.Notification {
			margin: 5px auto;
		}
		.view-all {
			cursor: pointer;
			width: 100%;
			font-size: 14px;
			text-align: center;
			color: $lighter-text;
			margin-bottom: 12px;
			&:hover {
				color: $dark-text;
			}
		}
		.no-activity {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 220px;
			margin: 60px auto;
			.icon {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 75px;
				width: 75px;
				border-radius: 50%;
				font-size: 40px;
				color: white;
				background-color: #e5e5e5;
				margin: 0 0 8px 0;
			}
			.header {
				font-size: 19px;
				font-weight: 600;
				margin: 10px 0;
				text-align: center;
			}
			.description {
				font-size: 14px;
				line-height: 18px;
				margin: 0 30px;
				text-align: center;
				color: $lighter-text;
				margin-bottom: 8px;
			}
		}
	}
}
