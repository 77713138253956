@use '../../assets/sass/variables' as *;

.appHeader {
	display: flex;
	background: $default-header;
	height: 8vh;
	.logo {
		display: flex;
		align-items: center;
		justify-content: center;
		min-width: 200px;
	}
	.logo img {
		max-height: 50px;
		margin: 1vw auto;
		padding: 0 0.5vw;
	}
	.ant-layout-header {
		color: $white;
		font-size: 1rem;
		flex-grow: 1;
		background: $default-header;
		height: 100%;
		padding: 0 30px 0 50px;
		#innerHeaderContainer {
			display: flex;
			height: 8vh;
			gap: 0.5em;
			.headerMenuIcon {
				display: flex;
				align-items: center;
				justify-content: center;
			}
			h1 {
				color: white;
				font-size: 1em;
				margin: 0;
				flex: 8;
				text-align: center;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				line-height: 8vh;
				width: 50vw;
			}
			.headerRightIcon {
				display: flex;
				align-items: center;
				align-self: center;
				justify-content: flex-end;
			}
		}
	}
}

.drawer .ant-menu {
	background: $white;
	.ant-menu-item {
		color: $darkBlue;
	}
	.ant-menu-item.ant-menu-item-selected .anticon {
		color: $white;
	}
}

body .ant-menu.ant-menu-dark .ant-menu-item-selected {
	background: $darkBlue;
	color: $white;
}

@media screen and (max-width: 767px) {
	.ant-layout-header {
		padding: 0 20px;
	}
	#innerHeaderContainer h1 {
		width: 100%;
	}
}
