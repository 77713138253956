@use '../../assets/sass/mixins' as *;

@include popover;

.UserNav {
	min-width: 350px;
	color: rgba(0, 0, 0, 0.8);
	.title {
		font-size: 18px;
		font-weight: 600;
		height: 60px;
		margin-bottom: 4px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.anticon {
			margin: 10px;
			color: #666666;
			&.anticon-setting {
				font-size: 20px;
			}
			&:hover {
				color: #000000;
			}
		}
	}
	.horizontal-divider {
		height: 1px;
		width: 100%;
		background-color: #e6e6e6;
	}
	.footer {
		.navLinks {
			margin: 10px 5px;
			font-weight: 600;
			> div {
				@include link;
			}
		}
	}
}
