.floating-podcasts-player {
	position: absolute;
	padding: 10px 1.5em;
	bottom: 3%;
	right: 2%;
	height: 80px;
	width: 275px;
	border-radius: 100px;
	color: #ffffff;
	display: flex;
	gap: 10px;
}
