.podcasts-container {
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 25px;
	padding: 40px;
	overflow-y: scroll;
	width: 100%;
	@media screen and (max-width: 640px) {
		padding: 40px 30px;
	}
	.featured-podcast {
		display: flex;
		flex-direction: column;
		gap: 10px;
		padding-bottom: 10px;
		.featured-tag {
			padding: 8px 18px;
			width: fit-content;
			border-radius: 50px;
			font-size: 13px;
			font-weight: 600;
		}
	}
}
