.NotificationPreferencesContainer {
	.notificationPreferencesWebView {
		margin: 10px 0 18px 0;
		.ant-table {
			.ant-table-thead > tr > th {
				background-color: rgba(255, 255, 255, 0);
				text-align: right;
				&:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
					opacity: 0;
				}
			}
			.ant-table-thead > tr > th,
			.ant-table-tbody > tr > td {
				color: rgba(0, 0, 0, 0.6);
				font-size: 16px;
				border: none;
			}
			td.ant-table-cell {
				&:not(:first-child) {
					text-align: right;
				}
			}
		}
	}
	.notificationPreferencesMobile {
		display: flex;
		flex-direction: column;
		margin: 3vw;
		height: 50%;
		padding: 15px;

		.notificationSetting {
			display: flex;
			flex-direction: column;
			border-bottom: 0.15mm solid black;
			padding-top: 3vh;
			padding-bottom: 3vh;

			small {
				color: gray;
			}

			.notificationSelection {
				display: flex;
				justify-content: flex-end;
				padding: 5px;
			}

			h4 {
				margin-right: auto;
			}
		}
	}
}
