$avatar-circle-dims: 50px;

.HeaderAvatar {
	cursor: pointer;
	.avatar-wrapper {
		width: $avatar-circle-dims;
		height: $avatar-circle-dims;
		display: flex;
		.ProgressiveAvatar {
			width: $avatar-circle-dims;
			height: $avatar-circle-dims;
			img,
			.initials {
				border: 2.5px solid white;
				border-radius: 50%;
			}
		}
		.ant-badge-count-sm {
			min-width: 16px;
			height: 16px;
			border-radius: 8px;
			font-weight: 600;
			// box-shadow: none;
		}
	}
}
