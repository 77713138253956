.Notification {
	display: flex;
	justify-content: space-between;
	padding: 14px;
	border-radius: 10px;
	cursor: pointer;
	&:hover {
		color: rgba(0, 0, 0, 0.9);
		background-color: #f4f4f4;
	}
	.left {
		display: flex;
		.note-info {
			max-width: 235px;
			margin-left: 14px;
			a {
				color: inherit;
			}
			.description {
				line-height: 20px;
				margin-bottom: 4px;
			}
			.time {
				font-size: 12px;
				line-height: 18px;
				color: rgba(0, 0, 0, 0.4);
				font-weight: 500;
			}
		}
	}
	.right {
		.unread-icon {
			height: 14px;
			width: 14px;
			margin: 14px 8px;
			border-radius: 50%;
			background-color: #67ab5d;
		}
	}
	&.is-read {
		.right {
			.unread-icon {
				opacity: 0;
			}
		}
	}
}
