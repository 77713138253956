.podcast-layout-container {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	gap: 35px;
	width: 100%;
	padding: 40px;
	overflow-y: scroll;
	@media screen and (max-width: 640px) {
		padding: 40px 30px;
	}
}
