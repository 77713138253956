.volume-controls-container {
	.volume-controls {
		display: flex;
		align-items: center;
		gap: 8px;
		.volume-slider {
			width: 100%;
		}
	}
}
