$secondary-color: #33435a;

@mixin flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

@mixin outfit {
	font-family: 'Outfit', sans-serif;
}
@mixin title-font {
	@include outfit;
}

@mixin title {
	@include title-font;
	color: $secondary-color;
	font-size: 26px;
	font-weight: 300;
	line-height: 1.15;
	text-wrap: nowrap;
}

@mixin pageSearchBar {
	width: 100%;
	input {
		border-radius: 10px 0 0 10px;
		padding: 10px 10px 10px 20px;
		line-height: 25px;
	}
	.ant-input-search-button {
		height: 47px;
		width: 47px;
	}
	.ant-input-search-button,
	.ant-input-group-addon {
		border-radius: 0 10px 10px 0 !important;
	}
}

@mixin popover {
	.ant-popover {
		border-radius: 10px;
		.ant-popover-content {
			.ant-popover-arrow {
			}
			.ant-popover-inner {
				border-radius: 12px;
				.ant-popover-inner-content {
					// width: 100%;
				}
			}
		}
	}
}

@mixin link {
	color: rgba(0, 0, 0, 0.8);
	font-weight: 600;
	cursor: pointer;
	&:hover {
		color: #000000;
	}
}

@mixin toggle {
	$toggle-handle-diameter: 26px;
	$toggle-inner-padding: 1px;
	.ant-switch {
		min-width: 60px;
		height: $toggle-handle-diameter + (2 * $toggle-inner-padding);
		background-color: #89888e;
		filter: brightness(120%);
		.ant-switch-handle {
			top: $toggle-inner-padding;
			left: $toggle-inner-padding;
			width: $toggle-handle-diameter;
			height: $toggle-handle-diameter;
			&::before {
				border-radius: calc($toggle-handle-diameter / 2);
				box-shadow: none;
			}
		}
		&.ant-switch-checked {
			background-color: #4994ec;
			filter: brightness(100%);
			.ant-switch-handle {
				left: calc(100% - #{$toggle-handle-diameter} - #{$toggle-inner-padding});
			}
		}
		.ant-switch-inner {
			font-size: 14px;
		}
	}
}

@mixin loginPage {
	height: 100%;
	min-height: 80vh;
	@include flex-center;
	flex-direction: column;
	font-size: calc(10px + 2vmin);
	color: white;
	input {
		height: 70px;
		border-radius: 2px;
		font-size: 25px;
		color: #5b5b5b !important;
		padding-left: 35px !important;
		&::placeholder {
			color: #5b5b5b;
		}
		&:-webkit-autofill::first-line {
			font-size: 25px;
		}
	}
	img {
		width: 100%;
		margin: auto;
		margin-bottom: 50px;
	}
	&.container {
		position: relative;
		max-width: 400px;
		overflow-y: scroll;
		-ms-overflow-style: none;
		scrollbar-width: none;
		&::-webkit-scrollbar {
			display: none;
		}
	}
	.logo,
	.ant-form {
		transition: opacity 1s;
	}
	.logo {
		margin-top: 10%;
	}
	.ant-spin {
		display: none;
	}
	.ant-form {
		width: 95%;
		min-height: 275px;
		.ant-form-item {
			&.hidden {
				display: none;
			}
		}
		.ant-form-item-children {
			display: block;
		}
		.ant-input-affix-wrapper .ant-input-prefix {
			font-size: 18px;
			left: 10px !important;
		}
	}
	#loginWarningText {
		color: white;
		text-align: center;
		padding: 0 0.5vw;
	}
	.ant-btn-primary {
		width: 100%;
		height: 70px;
		border-radius: 3px;
		margin: auto;
		display: block;
		font-size: 30px;
		text-transform: uppercase;
		border: none;
	}
	input,
	.ant-btn-primary {
		height: 51px;
		font-size: 23px;
	}
	&.loading {
		.logo,
		.ant-form {
			opacity: 0;
			transform: scale(0);
			display: none;
		}
		.ant-spin {
			display: block;
		}
	}
	&.loaded {
		.logo,
		.ant-form {
			opacity: 1;
			transform: scale(1);
			display: block;
		}
	}
}

// breakpoints
$screen-xs-max: 575px;
$screen-sm-min: 576px;
$screen-md-min: 786px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;
$screen-xxl-min: 1600px;

@mixin xs {
	@media (max-width: #{$screen-xs-max}) {
		@content;
	}
}
@mixin sm {
	@media (min-width: #{$screen-sm-min}) {
		@content;
	}
}
@mixin md {
	@media (min-width: #{$screen-md-min}) {
		@content;
	}
}
@mixin lg {
	@media (min-width: #{$screen-lg-min}) {
		@content;
	}
}
@mixin xl {
	@media (min-width: #{$screen-xl-min}) {
		@content;
	}
}
@mixin xxl {
	@media (min-width: #{$screen-xxl-min}) {
		@content;
	}
}
