.podcast-media-preview {
	display: flex;
	gap: 15px;
	width: 100%;
	@media screen and (min-width: 1160px) {
		width: 420px;
	}
	.art-container {
		height: 130px;
		width: 130px;
		flex-shrink: 0;
		.podcast-art {
			height: 100%;
			width: 100%;
			border-radius: 8px;
			object-fit: cover;
		}
	}
	.content-container {
		flex-shrink: 1;
		width: 100%;
		display: flex;
		flex-direction: column;
		@media screen and (min-width: 1160px) {
			justify-content: space-between;
		}

		height: 130px;
		.podcast-date-type-duration {
			display: flex;
			align-items: center;
			gap: 8px;
			padding-bottom: 5px;
			.date {
				text-transform: uppercase;
			}
			.type {
				text-transform: uppercase;
			}
			.circle-divider {
				display: flex;
				width: 4px;
				height: 4px;
				background: #ffffff;
				border-radius: 50%;
			}
		}
		.podcast-title {
			font-weight: 600;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 1;
			line-clamp: 1;
			-webkit-box-orient: vertical;
			width: 100%;
		}
		.podcast-description {
			padding-bottom: 5px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			line-clamp: 2;
			-webkit-box-orient: vertical;
			width: 100%;
		}
		.podcast-actions {
			display: flex;
			align-items: center;
			gap: 5px;
			.play-pause-btn {
				cursor: pointer;
				display: flex;
				align-items: center;
				gap: 5px;
				padding: 5px 15px;
				width: fit-content;
				border-radius: 50px;
				svg {
					width: 12px;
					height: 12px;
				}
			}
		}
	}

	&.up-next {
		.art-container {
			width: 100px;
			height: 100px;
			.podcast-art {
				height: 100%;
				width: 100%;
				border-radius: 8px;
				object-fit: cover;
			}
		}
		.content-container {
			display: flex;
			max-width: 200px;
			height: 100px;
			justify-content: flex-start;
			gap: 10px;

			.podcast-date-type-duration {
				padding-bottom: 0;
				.date {
					font-size: 12px;
				}
				.type {
					font-size: 12px;
				}
			}
			.podcast-title {
				font-size: 14px;
				line-height: normal;
				-webkit-line-clamp: 2;
				line-clamp: 2;
			}
			.podcast-description {
				display: none;
			}
		}
	}

	&.side-player-open {
		width: 100%;
		.content-container {
			justify-content: flex-start;
		}
	}
}
