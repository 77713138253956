$dark-text: #000000;
$lighter-text: #888888;

$item-height: 40px;
$avatar-diameter: 40px;
$name-margin-left: 14px;

.UserInfo {
	cursor: pointer;
	height: $item-height;
	display: flex;
	align-items: center;
	color: $dark-text;
	margin-left: 5px;
	.ProgressiveAvatar {
		min-width: $avatar-diameter;
	}
	.nameAndProfile {
		margin-left: $name-margin-left;
		position: relative;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		.name {
			font-size: 16px;
			line-height: 20px;
		}
		.visitProfile {
			font-size: 12px;
			line-height: 20px;
			color: $lighter-text;
			user-select: none;
		}
	}
	&:hover {
		.nameAndProfile {
			.visitProfile {
				color: #666666;
			}
		}
	}
}
