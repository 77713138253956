.podcast-actions-menu {
	display: flex;
	align-items: center;
	.icon-menu {
		font-size: 18px;
		cursor: pointer;
	}
}

.toggle-played-btn {
	.action-opt {
		display: flex;
		align-items: center;
		gap: 6px;
	}
}
