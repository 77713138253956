@use '../../assets/sass/mixins' as *;
$light-text: #666666;

.ProgressiveAvatar {
	@include flex-center;
	.icon {
		@include flex-center;
		height: 57%;
		width: 57%;
	}
	.initials {
		@include flex-center;
		font-size: 24px;
		font-weight: 500;
		background-color: #000000;
		color: $light-text;
		user-select: none;
	}
}
