#layoutsContainer {
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
}
#innerLayoutContainer {
	display: flex;
	height: 92vh;
}
#renderChildrenContainer {
	width: 100%;
	height: 100%;
	overflow: scroll;
}

@media (max-width: 767px) {
	#layoutsContainer {
		height: 100dvh;
	}

	// this (below) was probably doing something on safari on mobile
	// (that bottom bar that makes dev's lives terrible)
	// but this is not the right way to accomplish this
	// especially since we have some infinite scrolling divs
	// like directory
	// attempting: https://stackoverflow.com/questions/68094609/ios-15-safari-floating-address-bar
	// instead (above)

	// #renderChildrenContainer {
	// 	padding-bottom: 15vh;
	// }
}
