.up-next-preview-container {
	padding-top: 10px;
	padding-bottom: 15px;
	.section-header {
		padding-bottom: 10px;
		font-size: 16px;
		font-weight: 500;
	}
	.up-next-preview {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 45px;
		svg {
			height: 16px;
			min-width: 16px;
			cursor: pointer;
		}
		.left {
			display: flex;
			gap: 15px;
			align-items: center;
			.cover-art {
				width: 50px;
				min-height: 50px;
				border-radius: 6px;
				object-fit: cover;
			}
			.podcast-title {
				font-weight: 600;
				padding-bottom: 5px;
				line-height: normal;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				line-clamp: 2;
				-webkit-box-orient: vertical;
				width: 175px;
			}
		}
	}
}
