@use '../../../assets/sass/mixins' as *;

.podcast-player-container {
	position: absolute;
	padding: 20px 25px;
	bottom: 3%;
	right: 2%;
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: 375px;

	@media screen and (max-width: 640px) {
		position: absolute;
		right: 0;
		bottom: 0;
		width: 100vw;
		height: 100vh;
	}
	.close-btn {
		width: 30px;
		min-height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		align-self: flex-end;
		border-radius: 50%;
		cursor: pointer;
	}
}
