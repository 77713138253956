@use '../../assets/sass/variables' as *;
.menuContainer {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	background-color: $default-menu;
	.StickTop {
		display: flex;
		flex-direction: column;
		padding: 1em 0;
		.menuItem {
			display: flex;
			align-items: center;
			cursor: pointer;
			color: $default-menu-text;
			padding: 1em 1.5em;
			gap: 1em;
			.menuName {
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				font-weight: 500;
			}
			svg {
				min-width: 1em;
				font-size: 1.5em;
			}
		}
	}
	.StickTop {
		overflow-y: scroll;
		scrollbar-width: none;
	}
}
.menuContainer ::-webkit-scrollbar {
	display: none;
}
.ant-layout-sider-collapsed {
	.menuName {
		font-size: 0 !important;
	}
	i {
		margin: auto;
	}
	#announcementBadge {
		position: relative;
		right: 22px;
		align-self: center;
	}
	#announcementBadge p {
		font-size: 0 !important;
		margin: 0;
	}
}
#collapseButton {
	width: 100%;
	height: 40px;
	background-color: $default-menu;
	border: none;
	outline: none;
	color: $default-menu-text;
	border-radius: 0;
	.CollapseIcon {
		font-size: 1.5em;
		display: flex;
		justify-content: center;
	}
}

@media screen and (max-width: 767px) {
	.menuContainer {
		height: 100vh;
		padding: 1vw;
	}
	#collapseButton {
		display: none;
	}
	.ant-drawer-content-wrapper > .ant-drawer-content > .ant-drawer-wrapper-body > .ant-drawer-body {
		padding: 0;
	}
}
